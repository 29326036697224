const Logo = 'https://assets.thegratitudebear.com/AllBearsLogo.png';
const Loading = () => (
  <div className="h-screen w-screen flex justify-center items-center bg-black">
    <div className="flex flex-col items-center gap-3">
      <img
        src={Logo}
        alt="Logo"
        className="w-full h-10 lg:h-20 object-contain"
      />
      <h1 className="font-bold text-3xl text-off-white">Loading...</h1>
    </div>
  </div>
);
export default Loading;
